import {
  AdminRecurringFrequenciesType,
  FrequenciesType,
  InputsType,
  KeysType,
  StepsType,
  SubscriptionFrequenciesType,
  TasksType,
  VehiclesType,
} from "./types";

export const Key: KeysType = {
  Fields: "fields",
  Value: "value",
  Touched: "touched",
  isValid: "isValid",
  Validation: "validation",
  Error: "error",
};

export const Step: StepsType = {
  BubbleRedirect: "bubble_redirect",
  Initial: "initial",
  DateAndTime: "date-and-time",
  DoItAgainPreview: "do-it-again-preview",
  TaskDescription: "task-description",
  Address: "address",
  ReviewOrder: "review-order",
  Payment: "payment",
  QuoteReview: "quote-review",
  BidQuoteReview: "bid-quote-review",
  SubscriptionFrequency: "subscription_frequency",
  CarbonFootprint: "carbon_footprint",
};

export const Input: InputsType = {
  Task: "task",
  ZipCode: "zip_code",
  BusinessClient: "business_client",
  Date: "date",
  Time: "time",
  Pickup: "pickup",
  PickupDate: "pickup-date",
  PickupTime: "pickup-time",
  Frequency: "frequency",
  FrequencyPattern: "frequencyPattern",
  FrequencyRepeat: "frequencyRepeat",
  FrequencyRepeatMode: "frequencyRepeatMode",
  SubscriptionFrequency: "subscription_frequency",
  Vehicle: "vehicle",
  Junk: "products",
  Dumpster: "services",
  Description: "description",
  Stairs: "stairs",
  Dismantling: "dismantling",
  PickupAddress: "address",
  PickupAddressAdditional: "address_aditional",
  TermsOfService: "tos_checked",
  UnderstandPricing: "understand_pricing_checked",
  PaymentMethod: "payment_method",
  PaymentFirstName: "first_name",
  PaymentLastName: "last_name",
  PaymentEmail: "email",
  PaymentPhone: "phone_number",
  TaskImages: "taskImages",
  CompanyName: "companyName",
};

export const Task: TasksType = {
  JunkRemoval: "junk-removal",
  CardboardRemoval: "cardboard-removal",
  DumpsterRental: "dumpster-rental",
  PowerWashing: "power-washing",
};

export const Frequency: FrequenciesType = {
  Once: "once",
  Weekly: "weekly",
  Monthly: "monthly",
};

export const SubscriptionFrequency: SubscriptionFrequenciesType = {
  Monthly: "monthly",
  Bundle: "bundle",
};

export const adminRecurringFrequency: AdminRecurringFrequenciesType = {
  ...Frequency,
  Biweekly: "bi-weekly",
  Bimonthly: "bi-monthly",
  Quarterly: "quarterly",
  SemiAnnually: "semi-annually",
};

export const Vehicle: VehiclesType = {
  SUV: "SUV",
  Truck: "truck",
  PickupTruck: "pick-up-truck",
};

const requiredField = "This field is required.";
const minCharacterLimit = "Please add a bit more to meet the character minimum.";
const dateOutOfRange = "Date is out of range.";

export const RequiredTextFieldErrorCode = {
  required: requiredField,
  minCharacters: minCharacterLimit,
};

export const ZipCodeFieldErrorCode = {
  InvalidZipCode: "invalid-zip-code",
  ZipCodeNotSupported: "zip-code-not-supported",
};

export const DateFieldErrorCode = {
  required: requiredField,
  invalidDate: "invalid-date",
};

export const DateFieldOutOfRangeErrorCode = {
  required: dateOutOfRange,
  invalidDate: "invalid-date",
};

export const TimeFieldErrorCode = {
  required: requiredField,
  invalidDate: "invalid-time",
};

export const DumpsterFieldErrorCode = {
  required: requiredField,
  invalidDumpster: "At least one Dumpster Size is required.",
  understandPricing: "Dumping Terms acceptance is required.",
};

export const PhoneFieldErrorCode = {
  required: requiredField,
  invalidPhone: "Phone number is not valid.",
  maxTrials: "Too many attempts, try again later",
  invalidMobile: (cType: string) => `We send important order tracking information via SMS and you entered a ${cType}. Please enter a mobile number.`,
};

export const VehicleFieldErrorCode = {
  required: "Vehicle Selection is required.",
};

export const JunkFieldErrorCode = {
  required: requiredField,
  invalidJunk: "At least one Pickup Type is required.",
};

export const EmailFieldErrorCode = {
  required: requiredField,
  invalidEmail: "Email is not valid.",
};

export const AddressFieldErrorCode = {
  required: "Address is required.",
  invalidAddress: "Please enter a valid address.",
  AddressNotSupported: "Sorry! We don't serve your area.",
};

export const TermsOfServiceFieldErrorCode = {
  required: "Terms of Service acceptance is required.",
};

export const companyNameFieldErrorCode = {
  required: "Company Name is required.",
};
