export enum Task {
  JunkRemoval = "junk-removal",
  CardboardRemoval = "cardboard-removal",
  DumpsterRental = "dumpster-rental",
  PowerWashing = "power-washing"
}

export enum Vehicle {
  SUV = "SUV",
  Truck = "truck",
  PickupTruck = "pick-up-truck"
}
